const descStyle = {
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '32px',
  color: '#767676',
};
const descHeadStyle = {
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '32px',
  color: '#333333',
};

/* eslint-disable global-require */
export const HowWeDoItData = [
  {
    id: 1,
    title: 'Daily Challenges',
    description: (
      <p style={descHeadStyle}>
        Receive daily challenges centered around the habits of the longest living people in the world. The challenges
        are customizable, holistic, and manageably progressive over the 10 weeks of the challenge.
        <br />
        <br />
        <span style={descStyle}>
          Keep track of your daily goal and be inspired by daily quotes to keep striving for The New Life!
        </span>
      </p>
    ),
    image: require('assets/how_we_do/howWeDoItLeftMobile.png'),
    imageBgColor: 'rgba(140, 89, 226, 0.15)',
    imageAlt: 'collaboration icon',
    icon: require('assets/how_we_do/dailyChallenge.png'),
  },
  {
    id: 2,
    title: 'Community/ Accountability Parter',
    description: (
      <p style={descHeadStyle}>
        {`Choose up to 10 accountability partners, encourage each other through the whole program, see each other's
        progress and send messages of encouragement.`}
        <br />
        <br />
        <span style={descStyle}>
          Comments are specifically curated by TNL to avoid trolling and negativity (never helpful to our health
          journey). Choose between compassionate, competitive, and celebratory comments to spur others on in their
          health journey.
        </span>
      </p>
    ),
    image: require('assets/how_we_do/goalsMobile.png'),
    imageBgColor: 'rgba(41, 163, 167, 0.15)',
    imageAlt: 'healthy life style icon',
    icon: require('assets/how_we_do/community.png'),
  },
  {
    id: 3,
    title: 'Leaderboard',
    description: (
      <p style={descHeadStyle}>
        Easily keep track of your points, where you are on the leaderboard as well as your accountability partners so
        you can know when to celebrate and encourage them.
      </p>
    ),
    image: require('assets/how_we_do/howWeDoItLeftMobile.png'),
    imageBgColor: 'rgba(41, 163, 167, 0.15)',
    imageAlt: 'leaderboard icon',
    icon: require('assets/how_we_do/leaderboard.png'),
  },
  {
    id: 4,
    title: 'Goals/Oracle Prediction',
    description: (
      <p style={descHeadStyle}>
        What would a health journey be without fun?! Set goals for habits or challenges each day to get bonus points and
        focus your health journey in the areas that matter most to you such as your mental health, relationships, or
        diet.
        <br />
        <br />
        <span style={descStyle}>
          Each week, select someone who you want to challenge or encourage to really go after it, by predicting them to
          have the highest score for the week. If successful you both receive bonus points! Not sure who to pick?
        </span>
      </p>
    ),
    image: require('assets/how_we_do/goalsMobile.png'),
    imageBgColor: 'rgba(41, 163, 167, 0.15)',
    imageAlt: 'healthy life style icon',
    icon: require('assets/how_we_do/goals.png'),
  },
  {
    id: 5,
    title: 'Activities',
    description: (
      <p style={descHeadStyle}>
        Easily keep track of your points, where you are on the leaderboard as well as your accountability partners so
        you can know when to celebrate and encourage them.
      </p>
    ),
    image: require('assets/how_we_do/activities.png'),
    imageBgColor: 'rgba(41, 163, 167, 0.15)',
    imageAlt: 'healthy life style icon',
    icon: require('assets/how_we_do/activity.png'),
  },
];
