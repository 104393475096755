// Define all generic enums here.

/* eslint-disable */
export enum ScreenSizes {
  MOBILE = 640,
  TABLET = 768,
  LARGE_TABLET = 1024,
  DESKTOP = 1280,
  LARGE_DESKTOP = 1440,
}

export enum NotifierTitle {
  GENERIC = 'generic',
  LOGIN = 'login',
  SIGNUP = 'signup',
  CONFIRMSIGNUP = 'confirmsignup',
  FORGOTPASSWORD = 'forgotpassword',
  FORGOTPASSWORDSUBMIT = 'forgotpasswordsubmit',
  OAUTH = 'oauth',
  STRIPE = 'Stripe',
  CHANGEPASSWORD = 'ChangePassword',
  LIST_USERS = 'ListUsers',
  ADD_USERS = 'AddUsers',
  CORPORATION_DETAILS = 'Corporation Details',
  USER_DETAILS = 'USER DETAILS',
  UPDATE_PROFILE = 'UPDATE_PROFILE',
  UPDATE_PROFILE_PICTURE = 'UPDATE_PROFILE_PICTURE',
  DELETE_USERS = 'DELETE_USERS',
  GET_IN_TOUCH = 'GET_IN_TOUCH',
}

export enum Orientation {
  LANDSCAPE = 'LANDSCAPE',
  PORTRAIT = 'PORTRAIT',
  SQUARE = 'SQUARE',
}

export enum StatName {
  exercise = 'Exercise',
  education = 'Education',
  enjoy_sunlight = 'Sunlight',
  invest_in_others = 'Invest',
  trust_more_and_stress_less = 'Trust',
  live_temperately = 'Live',
  have_refreshing_rest = 'Rest',
  nutrition = 'Nutrition',
  fresh_air = 'Air',
  water = 'Water',
}
