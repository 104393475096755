import { FC } from 'react';
import { Button } from 'components/ui/Button/Button';
import { FAQCard } from 'components/ui/Card';
import { Spacer } from 'components/ui/Spacer';
import faqTopImage from '../../assets/faq/faq.png';

export const FAQsMain: FC = () => {
  const faqType = ['Company', 'The New Life App', 'Blog Post', 'Challenges', 'Health tips', 'Process'];

  return (
    <>
      <div>
        <div className="relative w-full">
          <img src={faqTopImage} alt="faq main" className="h-auto w-full" />
          <div className="max-w-screen-antd-xl m-auto absolute bottom-14 w-full left-1/2 -translate-x-1/2">
            <p className="bannerCaption">Frequently</p>
            <p className="bannerCaption mt-4">Asked Questions</p>
          </div>
        </div>
      </div>
      <Spacer height="40px" />

      <div className="max-w-screen-antd-xl mx-auto">
        <div className="flex gap-4">
          {faqType.map((type) => (
            <Button className="border-[#EDEEF0] text-secondary bg-[#EBF8FA] py-2 px-8 h-12" key={type}>
              {type}
            </Button>
          ))}
        </div>
        <Spacer height="60px" />

        <FAQCard
          title="What is The New Life Challenge?"
          desc="It is a 10-week wholistic (yes we spelled this way intentionally :D) welness program designed to facilitate the development of 10 healthy habits based upon the longest living Americans and scientific research."
        />
        <Spacer height="20px" />

        <FAQCard
          title="What precautions should I take if I have a specific medical condition?"
          desc="Ensure that you are in touch with your primary care physician about your involvement in the program to make sure you are not harming yourself and instead heading yourself."
        />
        <Spacer height="20px" />

        <FAQCard
          title="Is this program considered medical advice?"
          desc="No, we are not physicians and so if you experience any discomfort while going through the TNLC program, please contact your physician immediately."
        />

        <Spacer height="20px" />

        <FAQCard
          title="How do you know that these lifestyle habits can add 7-10 years to my life?"
          desc="7-10 years is the average number of years that the occupants of the North American Blue Zone in Loma Linda, CA live longer than the rest of us. the only difference between them and you is their habits of living. Which we have encapsulated in The New Life acronym. There are, of course, other factor such as genetics that may infulence this number higher or slightly lower."
        />
        <Spacer height="20px" />

        <FAQCard
          title="Is there health coaching?"
          desc="Yes, for a premium price we can have a live coach contact you to even further adapt the program to your needs and circumstances. However. we are excited to also note that we will be adding digital coaching into our next iteration of the app: D"
        />
        <Spacer height="20px" />

        <FAQCard
          title="How much time should I spend on challenges that do not have a set time allotted to them?"
          desc="For each of these challenges the goal is to engage in the activity or complete the task with complete focus and sincerity."
        />
        <Spacer height="20px" />

        <FAQCard
          title="What kind of comments are we looking for on the blog?"
          desc="We are simply looking for your reaction and practical uses of the information for yourself. Comments like, “I didn’t know that...” or “I feel that this idea is...” or “Wow! great article”. Now word limit, just your candid thoughts, we are not sensitive :D"
        />
        <Spacer height="20px" />

        <FAQCard
          title="Do we have to read and comment on each blog post?"
          desc="Yes, In an ideal world, this would be no problem, but we understand things happen. however, with the 7-Day point boost (see rules of the game section) you can gain immunity to a slip or if you want a break."
        />
        <Spacer height="80px" />
      </div>

      <div className="bg-[#F5F5F5] p-20">
        <div className="max-w-screen-antd-xl mx-auto">
          <div className="text-center">
            <h3 className="font-medium text-[40px] leading-[64px] text-secondaryDarkest">Still need answers?</h3>
          </div>
          <Spacer height="40px" />
          <div className="flex gap-8">
            <div className="flex flex-col items-center gap-4">
              <h3 className="font-medium text-[22px] leading-8 text-secondaryDarkest">Read our blog</h3>
              <p className="text-center w-9/12 font-normal text-base leading-7 mb-2">
                Read helpful articles, life-hacks, new features and in-depth information on the new life
              </p>
              <a
                href="https://www.getthenewlife.com/blog/"
                target="_blank"
                rel="noreferrer"
                className="border-primary text-primary w-[204px] h-[56px] font-semibold text-base border-solid rounded-full flex justify-center items-center hover:border-[#5CC9D8] bg-white"
              >
                Read Blog
              </a>
            </div>
            <div className="flex flex-col items-center gap-4">
              <h3 className="font-medium text-[22px] leading-8 text-secondaryDarkest">Email us</h3>
              <p className="text-center w-9/12 font-normal text-base leading-7 mb-2">
                Our customer service team will be happy to help you and respond as soon as possible.
              </p>
              <a
                href="mailto:info@getthenewlife.com"
                target="_blank"
                rel="noreferrer"
                className="border-primary text-primary w-[204px] h-[56px] font-semibold text-base border-solid rounded-full flex justify-center items-center hover:border-[#5CC9D8] bg-white"
              >
                Email Us
              </a>
            </div>
            <div className="flex flex-col items-center gap-4">
              <h3 className="font-medium text-[22px] leading-8 text-secondaryDarkest">Follow Us</h3>
              <p className="text-center w-9/12 font-normal text-base leading-7 mb-2">
                Follow us on our Instagram page to get all the latest, exciting promotions and updates.
              </p>

              <a
                href="https://instagram.com/getthenewlife?igshid=MzRlODBiNWFlZA=="
                target="_blank"
                rel="noreferrer"
                className="border-primary text-primary w-[204px] h-[56px] font-semibold text-base border-solid rounded-full flex justify-center items-center hover:border-[#5CC9D8] bg-white"
              >
                Instagram
              </a>
            </div>
          </div>
          <Spacer height="40px" />
        </div>
      </div>
    </>
  );
};
