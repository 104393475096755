import { FC, useEffect } from 'react';
import AOS from 'aos';
import { FAQCard } from 'components/ui/Card';
import { Spacer } from 'components/ui/Spacer';
import styles from './styles.module.scss';
import 'aos/dist/aos.css';

export const FAQs: FC = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className={styles.faqs}>
      <div className={styles.faqs__wrap}>
        <div className={styles.faqs__text__header} data-aos="fade-up" data-aos-duration="1000">
          <h1 className={styles.faqs__text__top}>FAQs</h1>
          <h1 className={styles.faqs__text__bottom}>Have a question? Here are the most frequently asked</h1>
          <h1 className={styles.faqs__text__bottom}>questions from our various communities.</h1>
        </div>
        <Spacer height="20px" />
        <FAQCard
          title="What is The New Life Challenge?"
          desc="It is a 10-week wholistic (yes we spelled this way intentionally :D) welness program designed to facilitate the development of 10 healthy habits based upon the longest living Americans and scientific research."
        />
        <Spacer height="20px" />

        <FAQCard
          title="What precautions should I take if I have a specific medical condition?"
          desc="Ensure that you are in touch with your primary care physician about your involvement in the program to make sure you are not harming yourself and instead heading yourself."
        />
        <Spacer height="20px" />

        <FAQCard
          title="Is this program considered medical advice?"
          desc="No, we are not physicians and so if you experience any discomfort while going through the TNLC program, please contact your physician immediately."
        />
        <Spacer height="20px" />

        <FAQCard
          title="How do you know that these lifestyle habits can add 7-10 years to my life?"
          desc="7-10 years is the average number of years that the occupants of the North American Blue Zone in Loma Linda, CA live longer than the rest of us. the only difference between them and you is their habits of living. Which we have encapsulated in The New Life acronym. There are, of course, other factor such as genetics that may infulence this number higher or slightly lower."
        />
      </div>
    </div>
  );
};
